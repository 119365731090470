define("discourse/plugins/z-communiteq-features/discourse/templates/connectors/admin-below-plugins-index/discoursehosting", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style="padding: 12px; background-color: #fffbd6; color: #222;">
  In order to install plugins on Communiteq Discourse Hosting, please go to our control panel at <a target="_blank" href="https://controlpanel.communiteq.com/cp/">https://controlpanel.communiteq.com/cp/</a> and select Plugins from the left hand menu.
  </div>
  
  */
  {
    "id": "P07/mpqo",
    "block": "[[[10,0],[14,5,\"padding: 12px; background-color: #fffbd6; color: #222;\"],[12],[1,\"\\nIn order to install plugins on Communiteq Discourse Hosting, please go to our control panel at \"],[10,3],[14,\"target\",\"_blank\"],[14,6,\"https://controlpanel.communiteq.com/cp/\"],[12],[1,\"https://controlpanel.communiteq.com/cp/\"],[13],[1,\" and select Plugins from the left hand menu.\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/z-communiteq-features/discourse/templates/connectors/admin-below-plugins-index/discoursehosting.hbs",
    "isStrictMode": false
  });
});